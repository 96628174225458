// src/components/Card.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

function Card({person}) {
  
  const navigate = useNavigate();

  function handleClick(event) {
    navigate('/person-profile/dd');
  }

  return(
    <div onClick={handleClick}
    className="tc bg-lightest-blue dib br3 pa3 ma2 grow bw2 shadow-5">
      <img className="h6 w6" alt={person.name} src={process.env.PUBLIC_URL + person.imgPath} />
      <div>
        <h2>{person.name}</h2>
        <p>{person.location}</p>
      </div>
    </div>
  );
}

export default Card;