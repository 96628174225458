import React from 'react';
import Search from '../components/Search';
import initialDetails from '../data/initialDetails';

const Home = () => {
    return (
        <div className="tc ma0 pa4 min-vh-100">
          <Search details={initialDetails}/>
        </div>
      );
  };
  
  export default Home;