// src/data/initialDetails.js

const initialDetails = [
    {
      id: 1,
      imgPath: "/assets/images/1.jpeg",
      name: "Jane Doe",
      location: "New Delhi",
      address: "New Delhi, India",
    },
    {
      id: 2,
      imgPath: "/assets/images/2.jpeg",
      name: "Mary Rosamund",
      location: "Chandigarh",
      address: "Tbilisi, India",
    },
    {
      id: 3,
      imgPath: "/assets/images/3.jpeg",
      name: "Sherlock Watson",
      location: "Raipur",
      address: "Baker Street, India",
    },
    {
      id: 4,
      imgPath: "/assets/images/1.jpeg",
      name: "John Holmes",
      location: "Bhopal",
      address: "Baker Street, India",
    },
    {
      id: 5,
      imgPath: "/assets/images/3.jpeg",
      name: "Mycroft Lestrade",
      location: "Mumbai",
      address: "Parsi Road, India",
    },
    {
      id: 3,
      imgPath: "/assets/images/3.jpeg",
      name: "Sherlock Watson",
      location: "Raipur",
      address: "Baker Street, India",
    },
    {
      id: 4,
      imgPath: "/assets/images/1.jpeg",
      name: "John Holmes",
      location: "Bhopal",
      address: "Baker Street, India",
    },
    {
      id: 5,
      imgPath: "/assets/images/3.jpeg",
      name: "Mycroft Lestrade",
      location: "Mumbai",
      address: "Parsi Road, India",
    },
  ];
  
  export default initialDetails;